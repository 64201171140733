import ApplicationController from './application_controller'
import Rails from "@rails/ujs";

export default class extends ApplicationController {
  static targets = ["input", "results"];

  connect() {
    this.boundHandleClickOutside = this.handleClickOutside.bind(this);
    document.addEventListener("click", this.boundHandleClickOutside);

    this.selectedIndex = -1;
  }

  disconnect() {
    document.removeEventListener("click", this.boundHandleClickOutside);
  }

  search() {
    clearTimeout(this.typingTimer);
    // Optionally, you might check for the input target here as you do now.
    const query = this.inputTarget.value.trim();
    if (query.length < 3) {
      this.hideResults();
      return;
    }

    // Set a debounce delay (e.g., 300ms)
    this.typingTimer = setTimeout(() => {
      const searchUrl = this.element.dataset.searchUrl;
      Rails.ajax({
        type: "GET",
        url: `${searchUrl}?query=${encodeURIComponent(query)}`,
        success: (data) => {
          this.updateResults(data);
        },
        error: (data) => {
          console.error("Error fetching students:", data);
        }
      });
    }, 300);
  }

  updateResults(data) {
    this.selectedIndex = -1;  // Reset the navigation index when updating results
    this.resultsTarget.innerHTML = "";

    if (data.length === 0) {
      this.resultsTarget.innerHTML = `<div class="list-group-item text-muted">No matches found</div>`;
      this.hideResults();
      return;
    }

    this.resultsTarget.innerHTML = data.map((student) => `
      <div class="list-group-item list-group-item-action"
           data-action="mousedown->search#fillInput"
           data-name="${student.first_name} ${student.last_name}">
        ${student.first_name} ${student.last_name}
      </div>
    `).join("");

    this.resultsTarget.classList.remove("d-none");
  }

  fillInput(event) {
    const selectedItem = event.target.closest(".list-group-item");
    if (selectedItem) {
      const selectedName = selectedItem.dataset.name;
      if (selectedName) {
        this.inputTarget.value = selectedName;
        this.hideResults();
      }
    } else {
      console.error("No valid item clicked");
    }
  }

  hideResults() {
    this.resultsTarget.classList.add("d-none");
  }

  handleClickOutside(event) {
    if (!this.element.contains(event.target)) {
      this.hideResults();
    }
  }

  navigateResults(event) {
    const items = this.resultsTarget.querySelectorAll(".list-group-item");
    if (items.length === 0) return;

    if (event.key === "ArrowDown") {
      event.preventDefault();
      this.selectedIndex = (this.selectedIndex + 1) % items.length;
    } else if (event.key === "ArrowUp") {
      event.preventDefault();
      this.selectedIndex = (this.selectedIndex - 1 + items.length) % items.length;
    } else if (event.key === "Enter" && this.selectedIndex >= 0) {
      event.preventDefault();
      items[this.selectedIndex].click();
    }

    // Highlight selected item
    items.forEach((item, index) => {
      if (index === this.selectedIndex) {
        item.classList.add("active");
      } else {
        item.classList.remove("active");
      }
    });
  }
}
