import ApplicationController from './application_controller'

export default class extends ApplicationController {
    connect() {
        setTimeout(() => {
          this.dismiss();
        }, 5000);
      }

      dismiss() {
        this.element.classList.remove("show");

        setTimeout(() => {
          this.element.remove();
        }, 500); // 500ms is a common duration for fade transitions
      }
}
